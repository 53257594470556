<!-- CartItem.vue -->
<template>
  <div class="p-4 items-center grid grid-cols-2">
    <div class="flex gap-2 items-center">
      <a href="#" class="w-9 h-9 flex-shrink-0 flex">
        <img class="w-full h-auto" :src="product.image.file" :alt="product.name"/>
      </a>
      <div class="min-w-0">
        <p class="text-gray-900 font-semibold text-sm truncate">{{ product.reference_name }}</p>
        <p class="text-gray-500 font-normal text-sm truncate mt-0.5">{{ $formatNumber(product.price, 'currency') }}</p>
      </div>
    </div>
    <div class="flex items-center justify-end gap-3">
      <div class="relative flex items-center">
        <button type="button" @click="updateQuantity(product, product.quantity - 1)"
                class="bg-gray-200 border-gray-300 rounded-md w-5 h-5 flex items-center justify-center">
          <MinusIcon class="h-3 w-3 text-gray-700"/>
        </button>
        <input type="text" v-model.number="localQuantity"
               class="focus:outline-none focus:ring-0 text-gray-900 font-medium text-sm text-center bg-transparent border-0 w-10"
               @input="handleInputChange($event.target.value)"/>
        <button type="button" @click="updateQuantity(product, product.quantity + 1)"
                class="bg-gray-200 border-gray-300 rounded-md w-5 h-5 flex items-center justify-center">
          <PlusIcon class="h-3 w-3 text-gray-700"/>
        </button>
      </div>
      <button type="button" @click="removeProduct(product)">
        <TrashIcon class="h-5 w-5 text-red-500"/>
      </button>
    </div>
  </div>
</template>

<script setup>
import {useStore} from 'vuex';
import {TrashIcon, PlusIcon, MinusIcon} from '@heroicons/vue/24/outline';
import {defineEmits, getCurrentInstance, ref, watch} from "vue";

const {proxy} = getCurrentInstance();

const props = defineProps({
  product: Object,
});
const emit = defineEmits(['productRemoved']);

const store = useStore();

const updateQuantity = async (product, quantity) => {
  const numericQuantity = Number(quantity);

  if (numericQuantity < 1) return removeProduct(product);

  try {
    await store.dispatch('cart/updateCartItem', {id: product.id, quantity: numericQuantity})
  } catch (e) {
    proxy.$notify({
      type: 'danger',
      duration: 3000,
      html: e.message
    });
    localQuantity.value = props.product.quantity;

  }
};

const removeProduct = (product) => {
  store.commit('cart/delItemCart', product.id);
  emit('productRemoved');

};

const localQuantity = ref(props.product.quantity);

const handleInputChange = (value) => {
  const numericValue = Number(value);
  if (numericValue >= 1) {
    localQuantity.value = numericValue;
    updateQuantity(props.product, numericValue);
  } else {
    localQuantity.value = props.product.quantity;
  }
};

watch(() => props.product.quantity, (newQuantity) => {
  localQuantity.value = newQuantity;
});
</script>
