import axios from 'axios';
import store from "@/store";

export default {
    async createOrder(order) {
        try {
            const API_URL = store.getters['api_url'];

            const response = await axios.post(`${API_URL}/order`, order);
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar dados do catálogo:', error);
            throw error;
        }
    },
};
