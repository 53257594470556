import {createStore} from 'vuex';
import catalog from './modules/catalog';
import cart from "@/store/modules/cart";
import client from "@/store/modules/client";
import orders from "@/store/modules/orders";
import CatalogService from '@/services/catalogService';

export default createStore({
    modules: {
        catalog,
        client,
        cart,
        orders
    },
    state: {
        token: null,
        isLoading: false,
        sgaweb_url:'https://sgasistemas.sgaweb.com.br',
        // sgaweb_url:'http://localhost:8080/sgaweb',
        config: null,

    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setLoading(state, status) {
            state.isLoading = status;
        },
        SET_CONFIG(state, payload) {
            state.config = payload;
        }
    },
    getters: {
        token: state => state.token,
        isLoading: state => state.isLoading,
        config: state => state.config,
        sgaweb_url: state => state.sgaweb_url,
        api_url: state => `${state.sgaweb_url}/api/catalogs`

    },
    actions : {
        async initializeConfig({commit, rootState}){
            const id_cobol = rootState.catalog.catalog.id_hostcobol;
            const config =  await CatalogService.getConfig(id_cobol);
            config.nome = rootState.catalog.catalog.branch_name || 'SGALoja';
            commit('SET_CONFIG', config);
        }
    }
});