<script setup>
import {useStore} from 'vuex';
import {computed} from "vue";
import {FwbButton} from "flowbite-vue";
import {useRoute, useRouter} from 'vue-router';

const route = useRoute();
const router = useRouter();
const store = useStore();
const totalPrice = computed(() => store.getters['cart/totalPrice']);
const totalQuantity = computed(() => store.getters['cart/totalQuantity']);
const totalPriceOriginal = computed(() => store.getters['cart/totalPriceOriginal']);
const totalDiscount = computed(() => totalPriceOriginal.value - totalPrice.value);
const cartSummary = computed(() => store.getters['cart/cartSummary']);

const steps = {
  '/checkout/cart': {
    text: 'Ir para Pagamento',
    next: () => router.push('/checkout/payment')
  },
  '/checkout/payment': {
    text: 'Finalizar',
    next: async () => {
      await processPayment();
    }
  },
  default: {
    text: 'Continuar',
    next: () => router.push('/checkout/cart')
  }
};
const confirmation = computed(() => route.path === '/checkout/confirmation');
const buttonText = computed(() => {
  return steps[route.path]?.text || steps.default.text;
});

const handleAction = async () => {
  const action = steps[route.path]?.next || steps.default.next;
  await action();
};
const processPayment = async () => {
  try {
    await store.dispatch('orders/processOrder')
    sessionStorage.removeItem('cart')
    router.push('/checkout/confirmation')
  } catch (e) {
    console.log(e)
  }
};
</script>

<template>
  <div class="w-full lg:max-w-xs xl:max-w-md">
    <h2 class="hidden sm:block text-xl font-semibold text-gray-900 dark:text-white">Resumo</h2>

    <div class="bg-gray-50 border border-gray-200 rounded-md w-full ml-auto p-4 md:p-6 sm:mt-4 mt-6 space-y-6">

      <div class="flow-root">
        <div class="-my-3 divide-y divide-gray-200">
          <dl class="divide-y divide-gray-200 sm:text-base text-sm" v-if="confirmation">
            <div v-for="product in cartSummary" :key="product.id"
                 class="flex justify-between items-center">
              <div class="w-full py-2 pr-2 overflow-x-auto">
                <div class="flex items-center gap-4">
                  <div class="w-9 h-9 flex-shrink-0 flex">
                    <img class="w-full h-auto" :src="product.image.file" :alt="product.name"/>
                  </div>
                  <span class="truncate">{{ product.reference_name }}</span>
                </div>
              </div>
              <div class="text-center text-gray-900 font-normal text-base w-14 py-2 px-2">x{{ product.quantity }}</div>
              <div class="text-right text-base font-semibold text-gray-900 py-2 pl-2">{{
                  $formatNumber(product.price, 'currency')
                }}
              </div>
            </div>
          </dl>

          <dl class="flex items-center justify-between gap-4 py-3">
            <dt class="text-base font-normal text-gray-500 ">Subtotal ({{ totalQuantity }} itens)</dt>
            <dd class="text-base font-medium text-gray-900">
              {{ $formatNumber(totalPriceOriginal, 'currency') }}
            </dd>
          </dl>


          <dl class="flex items-center justify-between gap-4 py-3">
            <dt class="text-base font-normal text-gray-500">Desconto</dt>
            <dd class="text-base font-medium text-green-500">{{ $formatNumber(totalDiscount, 'currency') }}</dd>
          </dl>


          <dl class="flex items-center justify-between gap-4 py-3">
            <dt class="text-base font-bold text-gray-900">Total</dt>
            <dd class="text-base font-bold text-gray-900">{{
                $formatNumber(totalPrice, 'currency')
              }}
            </dd>
          </dl>
        </div>
      </div>

      <div class="space-y-3" v-if="!confirmation">

        <fwb-button size="lg" class="w-full rounded-md text-sm sm:text-md lg:text-lg px-2 sm:px-4 lg:px-6" type="button"
                    @click="handleAction"
        >
          {{ buttonText }}

        </fwb-button>

        <div class="flex items-center justify-center gap-2">
          <span class="text-sm font-normal text-gray-500"> ou </span>
          <router-link
              to="/"
              class="inline-flex items-center gap-2 text-md font-medium text-blue-700 underline hover:no-underline">
            Continue Comprando
            <svg class="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 12H5m14 0-4 4m4-4-4-4"></path>
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>