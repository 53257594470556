<template>
  <div class="flex flex-col min-h-screen bg-gray-50">
    <Navbar />
    <main class="flex-grow">
      <router-view />
    </main>
    <Footer />
  </div>
</template>

<script>

import Navbar from '../components/MainNavbar.vue';
import Footer from '../components/MainFooter.vue';

export default {
  components: {
    Navbar,
    Footer,
  },

};
</script>
