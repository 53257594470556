<template>
  <div class="container mx-auto sm:p-6 py-6 px-2">
    <div class="mb-2 items-center justify-between space-y-4 sm:flex sm:space-y-0 md:mb-3">
      <BreadCrumbs/>

      <div class="flex justify-between gap-3">

        <button @click="isSidebarOpen = true" type="button"
                class="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 sm:w-auto gap-1 sm:hidden">
          <FunnelIcon class="h-4 w-4 text-black-500"/>
          Filtros
        </button>

        <div class="daisy-dropdown w-full daisy-dropdown-end">
          <button type="button"
                  class="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 sm:w-auto gap-1">
            <BarsArrowDownIcon class="h-4 w-4 text-black-500"/>
            Ordenar
            <ChevronDownIcon class="h-4 w-4 text-black-500"/>
          </button>
          <ul tabindex="0"
              class="daisy-dropdown-content daisy-menu bg-base-100 rounded-lg z-[1] w-40 p-2 shadow left-1/2 transform -translate-x-1/2">
            <li><a @click="setSortOrder('asc-price')">Menor Preço</a></li>
            <li><a @click="setSortOrder('desc-price')">Maior Preço</a></li>
          </ul>
        </div>
      </div>
    </div>

    <SidebarLeft :isSidebarOpen="isSidebarOpen" @update:isSidebarOpen="isSidebarOpen = $event">
      <template #content>
        <div class="flex justify-between mb-1">
          <h5 class="font-semibold text-lg text-gray-600">Filtros</h5>
          <button class="btn btn-ghost " @click="isSidebarOpen=false">
            <x-mark-icon class="h-4 w-4 text-black-500"/>
          </button>
        </div>
        <SummaryCategories/>
      </template>
    </SidebarLeft>

    <div class="flex gap-4">
      <div class="daisy-card bg-base-100 rounded-md border w-80 hidden md:block">
        <SummaryCategories/>
      </div>

      <div class="w-full">
        <ProductList/>
      </div>
    </div>
  </div>


</template>

<script setup>
import {ref} from 'vue';

import ProductList from '../components/ProductCard/ProductList.vue';
import {BarsArrowDownIcon, ChevronDownIcon, FunnelIcon, XMarkIcon} from '@heroicons/vue/24/outline';
import SummaryCategories from "@/components/SummaryCategories.vue";
import SidebarLeft from "@/components/SidebarLeft.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import store from "@/store";

const isSidebarOpen = ref(false);
const setSortOrder = (order) => {
  store.commit('catalog/SET_SORT_ORDER', order);
}

</script>













