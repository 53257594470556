import store from '../store';
import moment from 'moment';

async function updateTokenIfNeeded(urlToken, storeToken) {
    const storedToken = storeToken || sessionStorage.getItem('token');

    if (urlToken && urlToken !== storedToken) {
        sessionStorage.setItem('token', urlToken);
        sessionStorage.removeItem('cart');
        store.commit('setToken', urlToken);
    } else if (!storeToken) {
        store.commit('setToken', storedToken);
    }
}

async function fetchCatalogIfNeeded(token, urlToken, storedToken) {
    const catalog = store.getters['catalog/catalogData'];
    if (!catalog || (urlToken && urlToken !== storedToken)) {
        try {
            await store.dispatch('catalog/fetchDataCatalog', token);
            await store.dispatch('initializeConfig');
        } catch (error) {
            console.error('Erro ao buscar dados do catálogo:', error);
            throw error;
        }
    }
    store.dispatch('cart/initializeCart');

}

export default async function authMiddleware(to, from, next) {
    const urlToken = to.query.token;
    const storeToken = store.getters['token'];
    const token = urlToken || storeToken || sessionStorage.getItem('token');

    await updateTokenIfNeeded(urlToken, storeToken);

    if (!token) {
        return next({name: 'not-found'});
    }

    try {
        const allowStatus = ['active', 'pending', 'in_progress'];
        await fetchCatalogIfNeeded(token, urlToken, storeToken);
        const catalog = store.getters['catalog/catalogData'];
        const cartLength = store.getters['cart/totalQuantity']
        const expired = moment().isAfter(catalog.expiration_date, 'day');
        const pre_selected = Boolean(Number(catalog.pre_selected));

        if (expired || !allowStatus.includes(catalog.status)){
            return next('/401')
        }
        if (pre_selected && cartLength > 0 && to.path !== '/checkout/cart') {
            next('/checkout/cart');
        } else {
            next();
        }
    } catch {
        next({name: 'not-found'});
    }
}
