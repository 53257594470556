import {h, render} from 'vue';
import NotificationList from '@/components/notification/NotificationList.vue';

const NotificationPlugin = {
    install(app) {

        const container = document.createElement('div');
        document.body.appendChild(container);

        const notificationManager = h(NotificationList);

        render(notificationManager, container);

        app.config.globalProperties.$notify = (notification) => {
            notificationManager.component.exposed.addNotification(notification);
        };
    }
};

export default NotificationPlugin;
