<script setup>
import {ref, computed} from 'vue';
const props = defineProps({
  images: {
    type: Array,
    required: true,
  },
});
const hover = ref(false);

const defaultImage = require('@/assets/images/not-image.gif');

const currentImage = computed(() =>
    props.images && props.images.length
        ? hover.value && props.images.length > 1
            ? props.images[1].file
            : props.images[0].file
        : defaultImage
);
</script>

<template>
  <div class="h-56 w-full"
       @mouseover="hover = true"
       @mouseleave="hover = false">
    <img :src="currentImage" alt="Product Image" class="mx-auto h-full object-contain"/>
  </div>
</template>

<style scoped>

</style>