<script setup>

// import {FwbButton, FwbModal} from "flowbite-vue";
import closedIcon from "@/assets/images/closed.svg"
import soldIcon from "@/assets/images/sold_box.svg"
import {useStore} from "vuex";
import moment from "moment/moment";
import {computed, watchEffect } from "vue";
import {useRouter} from 'vue-router';

const router = useRouter();
const store = useStore();
// const sold = false
const catalog = store.getters['catalog/catalogData'];
const order = catalog?.order_data;

watchEffect(() => {
  if (!order) {
    router.push('/404');
  }
});

const formattedPedido = computed(()=> order.order_sga ? `${order.id}-${order.order_sga}` : order.id)

// const expired = moment().isAfter(catalog.value.expiration_date, 'day');
// const allowStatus = ['active', 'pending', 'in_progress'];
// allowStatus.includes(catalog.status);
const soldOut = catalog?.status === 'completed';

</script>

<template>

  <div>
    <div class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"></div>
    <div
        class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full grid"
        tabindex="0">

      <div class="max-w-2xl self-center justify-self-center relative p-4 w-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

          <div class="p-4 md:p-5 text-center">
            <div class="flex justify-center mb-4">
              <img :src="soldOut ? soldIcon : closedIcon" alt="" class="w-24 h-24">
            </div>
            <div class="mb-5" v-if="!soldOut">
              <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Este catálogo está atualmente inativo e não pode ser acessado. <br>
                Para obter mais informações ou reativar o catálogo, entre em contato com o seu consultor.
              </h3>
            </div>
            <div class="mb-5" v-else>
              <h3 class="text-lg font-normal text-gray-500">
                Este catálogo foi finalizado.
              </h3>
              <div v-if="order">
                <p class="text-gray-500">
                  Abaixo estão os detalhes da ordem associada:
                </p>
                <ul class="mt-3 mb-4 text-gray-500">
                  <li>Número da Ordem: <span class="text-gray-800 font-semibold"> #{{ formattedPedido}} </span></li>
                  <li>Data de Finalização: <span class="text-gray-800 font-semibold"> {{ moment(order.order_date).format('DD/MM/YYYY') }}</span></li>
<!--                  <li>Valor Total: <span-->
<!--                      class="text-gray-800 font-semibold">{{ $formatNumber(order.total_amount, 'currency') }} </span></li>-->
                </ul>
              </div>
              <p class="text-gray-500">
                Para mais informações, entre em contato com o seu consultor. Se precisar de um novo catálogo,
                solicite ao consultor que gere um catálogo atualizado para você.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>