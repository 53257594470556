import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/main.css';
import '../node_modules/flowbite-vue/dist/index.css'
import globalUtilities from './plugins/globalUtilities';
import alertPlugin from "./plugins/alertPlugin";
import notificationPlugin from "@/plugins/notificationPlugin";
import VueTheMask from 'vue-the-mask'

const app = createApp(App);
app.use(router);
app.use(store);
app.use(globalUtilities);
app.use(alertPlugin);
app.use(notificationPlugin);
app.use(VueTheMask)

app.mount('#app');