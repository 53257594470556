<template>
  <div class="container mx-auto sm:p-6 py-6 px-2">
    <BreadCrumbs/>
    <div class="flex justify-center bg-white rounded mt-2 p-4">
      <div class="w-full sm:max-w-screen-lg">
        <client-form :client="client"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import ClientForm from "@/components/clientForm.vue";
import {useStore} from "vuex";
import {computed} from "vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

const store = useStore()
const client = computed(() => store.getters['client/clientData'])
</script>
