<template>
  <div class="daisy-dropdown daisy-dropdown-end">
    <div tabindex="0" role="button" class="daisy-btn daisy-btn-ghost daisy-btn-circle daisy-avatar">
      <div class="w-10 rounded-full overflow-hidden relative bg-gray-100">
        <img
            v-if="imageLoaded"
            :src="client.avatar"
            alt="avatar"
            @error="onImageError"
        />
        <svg v-else class="w-full h-auto text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path>
        </svg>
      </div>
    </div>
    <div tabindex="0"
         class="daisy-menu daisy-menu-sm daisy-dropdown-content bg-base-100 rounded-md z-[1] w-52 p-2 mt-1 shadow shadow-gray-300 divide-y divide-gray-200">
      <div class="flex items-center flex-col gap-1 px-2 pt-2 pb-3">
        <div class="w-10 rounded-full overflow-hidden relative bg-gray-100">
          <img
              v-if="imageLoaded"
              :src="client.avatar"
              alt="avatar"
              @error="onImageError"
          />
          <svg v-else class="w-full h-auto text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clip-rule="evenodd"></path>
          </svg>
        </div>
        <p class="text-gray-900 font-semibold text-sm truncate capitalize">Ola,
          {{ client.nome.split(" ").slice(0, 2).join(" ").toLowerCase() }}</p>
        <fwb-button color="light" size="sm" type="button" @click="handleMyProfileClick" class="rounded-md w-full mt-2">
          Meu Perfil
        </fwb-button>
      </div>
      <ul>
        <li class="">
          <a>
            <ArrowRightStartOnRectangleIcon class="h-4 w-4 text-black-500"/>
            Sair
          </a>
        </li>
      </ul>
    </div>
  </div>

</template>

<script setup>
import {ArrowRightStartOnRectangleIcon} from '@heroicons/vue/24/outline';
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import {FwbButton} from "flowbite-vue";

const router = useRouter();
const store = useStore();
const client = computed(() => store.getters['client/clientData']);

const handleMyProfileClick = (event) => {
  event.target.blur()
  router.push({name: 'profile-client'})
}
const imageLoaded = ref(true);

const onImageError = () => {
  imageLoaded.value = false;
};
</script>