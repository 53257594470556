<template>
  <fwb-alert border v-if="visible" :type="props.type"
             @close="closeAlert"
             class="border-t-4 max-w-lg z-[100] overflow-hidden relative pl-8"
             :class="{ 'border-yellow-500': props.type === 'warning',
                       'pr-8': showCloseButton
             }">
    <template #default="{ onCloseClick }">


        <component :is="iconComponent" class="w-5 h-5 mr-1 absolute left-2"/>


      <button v-if="showCloseButton" type="button" @click="onCloseClick"
              class="bg-transparent text-sm w-5 h-5 ms-auto inline-flex justify-center items-center absolute right-2 top-1/2 transform -translate-y-1/2 ">
        <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
        </svg>
      </button>

      {{ message }}

      <slot></slot>

      <div v-if="showProgressBar && props.duration" class="absolute bottom-0 left-0 right-0 h-[2px] bg-gray-300">
        <div class="h-full transition-all ease-linear" :class="progressBarClasses" :style="progressBarStyle"></div>
      </div>

    </template>

    <template #title>
      <slot name="title"></slot>
    </template>


  </fwb-alert>
</template>

<script setup>
import {ref, onMounted, computed, onUnmounted} from 'vue';
import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/vue/24/outline';
import {FwbAlert} from "flowbite-vue";

const emit = defineEmits(['close']);

const props = defineProps({
  type: {type: String, default: 'dark'},
  message: {type: String, required: false},
  duration: {type: Number, default: 5000},
  showCloseButton: {type: Boolean, default: true},
  showProgressBar: {type: Boolean, default: true},
});

const iconComponents = {
  success: CheckCircleIcon,
  danger: XCircleIcon,
  warning: ExclamationCircleIcon,
  info: InformationCircleIcon,
  dark: InformationCircleIcon,
};
const iconComponent = computed(() => iconComponents[props.type] || iconComponents['dark']);

const visible = ref(true);
const progressVisible = ref(false);
let timeoutId;

const progressBarStyle = computed(() => ({
  width: progressVisible.value ? '0%' : '100%',
  transitionDuration: `${props.duration}ms`,
}));

const progressBarClasses = computed(() => ({
  'bg-green-500': props.type === 'success',
  'bg-red-500': props.type === 'danger',
  'bg-yellow-500': props.type === 'warning',
  'bg-blue-500': props.type === 'info',
  'bg-gray-500': props.type === 'dark',
}));

const closeAlert = () => {
  visible.value = false;
  emit('close');
  clearTimeout(timeoutId);
};

const startTimer = () => {
  timeoutId = setTimeout(() => closeAlert(), props.duration);
  setTimeout(() => (progressVisible.value = true), 50);
};

onMounted(() => {
  if (props.duration) startTimer();
});

onUnmounted(() => clearTimeout(timeoutId));

</script>

<style scoped>
</style>