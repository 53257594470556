import stockService from '@/services/stockService';

export default {
    namespaced: true,
    state: {
        cart: [],
    },
    mutations: {
        ADD_ITEM_CART(state, payload) {
            state.cart.push(payload);
            sessionStorage.setItem('cart', JSON.stringify(state.cart));
        },
        delItemCart(state, id) {
            const index = state.cart.findIndex(item => item.id === id);
            if (index > -1) {
                state.cart.splice(index, 1);
                sessionStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        UPDATE_ITEM_CART(state, {id, quantity}) {
            const index = state.cart.findIndex(item => item.id === id);
            if (index > -1) {
                state.cart[index].quantity = quantity;
                sessionStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        updateItemCart(state, payload) {
            const index = state.cart.findIndex(item => item.id === payload.id);
            if (index > -1) {
                state.cart[index].quantity = payload.quantity;
                sessionStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        INITIALIZE_CART(state, catalog) {
            const savedCart = sessionStorage.getItem('cart');
            const parsedCart = JSON.parse(savedCart);
            const preSelected = Boolean(Number(catalog.pre_selected));
            const timestamp = Date.now();

            if (savedCart && parsedCart.length > 0) {
                state.cart = parsedCart;
            } else if (preSelected) {
                const availableItems = catalog.items
                    .filter(item => {
                        const tamanho = item.estoque.find(t => t.tamanho === item.size);
                        return tamanho && parseInt(tamanho.estoque) > 0;
                    })
                    .map((item, index) => ({
                        id: `${item.id}-${timestamp + index}`,
                        id_product: item.id,
                        reference: item.reference,
                        color: item.color,
                        size: item.size,
                        quantity: 1,
                    }));

                if (availableItems.length) {
                    state.cart.push(...availableItems);
                    sessionStorage.setItem('cart', JSON.stringify(state.cart));

                }
            }
        },
        CLEAR_CART(state) {
            state.cart = [];
            sessionStorage.removeItem('cart');
            console.log(sessionStorage.getItem('cart'))
        }

    },
    actions: {
        initializeCart({commit, rootState}) {
            const {catalog} = rootState.catalog;
            commit('INITIALIZE_CART', catalog);
        },

        async addToCart({commit, state, rootGetters}, payload) {
            try {
                commit('setLoading', true, {root: true});

                const {id_product, reference, color, size, quantity} = payload;
                const existingItem = state.cart.find(item =>
                    item.reference === reference &&
                    item.color === color &&
                    item.size === size
                );
                const quantityTotal = existingItem ? quantity + existingItem.quantity : quantity;
                const canAdd = await stockService.checkStock(id_product, reference, color, size, quantityTotal);
                if (canAdd) {
                    existingItem ? commit('UPDATE_ITEM_CART', {id: existingItem.id, quantity: quantityTotal})
                        : commit('ADD_ITEM_CART', payload);
                } else {
                    const product = rootGetters['catalog/getProductById'](id_product);
                    const available = product.estoque.some(e =>
                        e.tamanho === size && parseInt(e.estoque) > (existingItem ? existingItem.quantity : 0)
                    );
                    const message = available ? "Quantidade selecionado indisponível!" : existingItem
                        ? "Você já adicionou a quantidade total deste tamanho no carrinho!"
                        : `Tamanho <b>${size}</b> com estoque insuficiente!`;

                    throw new Error(message);
                }
            } finally {
                commit('setLoading', false, {root: true})
            }
        },
        async updateCartItem({commit, state, rootGetters}, payload) {
            try {
                commit('setLoading', true, {root: true});

                const {id, quantity} = payload;
                const existingItem = state.cart.find(item => item.id === id);
                const canAdd = await stockService.checkStock(existingItem.id_product, existingItem.reference, existingItem.color, existingItem.size, quantity);
                if (canAdd) {
                    commit('UPDATE_ITEM_CART', {id: existingItem.id, quantity: quantity});
                } else {
                    const product = rootGetters['catalog/getProductById'](existingItem.id_product);
                    const available = product.estoque.some(e =>
                        e.tamanho === existingItem.size && parseInt(e.estoque) > (existingItem ? existingItem.quantity : 0)
                    );
                    const message = available ? "Quantidade selecionado indisponível!" : existingItem
                        ? "Você já adicionou a quantidade total deste tamanho no carrinho!"
                        : `Tamanho <b>${existingItem.size}</b> com estoque insuficiente!`;

                    throw new Error(message);
                }
            } catch (error) {
                console.error('Erro ao adicionar produto ao carrinho:', error);
                throw error
            } finally {
                commit('setLoading', false, {root: true})
            }
        },
    },
    getters: {
        cartData: state => state.cart,
        totalQuantity: state => {
            return state.cart.reduce((total, item) => total + item.quantity, 0);
        },
        totalPrice: (state, getters, rootState) => {
            return state.cart.reduce((total, item) => {
                const product = rootState.catalog.products.find(p => p.id === item.id_product);
                return total + (product ? product.price * item.quantity : 0);
            }, 0);
        },
        totalPriceOriginal: (state, getters, rootState) => {
            return state.cart.reduce((total, item) => {
                const product = rootState.catalog.products.find(p => p.id === item.id_product);
                return total + (product ? (product.original_price > 0 ? product.original_price : product.price) * item.quantity : 0);
            }, 0);
        },
        cartSummary: (state, getters, rootState) => {
            return state.cart.map(item => {
                const product = rootState.catalog.products.find(p => p.id === item.id_product) || {};
                const {
                    reference = 'Unknown',
                    reference_name = 'Unknown Product',
                    color_name = 'Unknown',
                    price = 0,
                    original_price = 0,
                    discount = 0,
                    imagens = []
                } = product;

                return {
                    id: item.id,
                    id_product: product.id,
                    reference,
                    reference_name,
                    size: item.size,
                    color_name,
                    quantity: item.quantity,
                    price,
                    discount,
                    original_price,
                    image: imagens[0] || 'default-image.jpg'
                };
            });
        }
    },
}
