<script setup>
import {FwbButton, FwbInput, FwbSelect} from "flowbite-vue";
import AvatarClient from "@/components/AvatarClient.vue";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import {computed, getCurrentInstance, ref, watch} from "vue";
import moment from "moment";
import {fetchAddressByZip} from "@/services/cepService";
import {useStore} from "vuex";

const {proxy} = getCurrentInstance();

const emit = defineEmits(['save-client', 'cancel']);
const store = useStore();
const props = defineProps({
  client: Object,
  cancelButton: Boolean
});

const localClient = ref({...props.client});

watch(() => props.client, (newClient) => {
  localClient.value = {...newClient};
});

const cleanAndLimitDocument = (document) => {
  const cleanDocument = document.replace(/\D/g, '');
  const trimmedDocument = cleanDocument.replace(/^0+/, '');
  return trimmedDocument.padStart(11, '0');
};

const dataNascimento = computed({
  get() {
    return localClient.value.data_nascimento
        ? moment(localClient.value.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : '';
  },
  set(value) {
    localClient.value.data_nascimento = moment(value).format('DD/MM/YYYY');
  }
});
localClient.value.cgc_cpf = cleanAndLimitDocument(localClient.value.cgc_cpf);
const cgcCpf = computed({
  get() {
    return localClient.value.cgc_cpf
  },
  set(value) {
    localClient.value.cgc_cpf = value.replace(/\D/g, '');
  }
});

const defaultWithMessage = (fieldName, rule, validator) => {
  const defaultMessages = {
    required: `O campo ${fieldName} é obrigatório.`,
  };
  return helpers.withMessage(defaultMessages[rule] || `O campo ${fieldName} é inválido.`, validator);
};
const nameValidator = (value) => value.trim().split(/\s+/).length >= 2;
const phoneRegex = helpers.regex(/^\(\d{2}\) \d{4,5}-\d{4}$/);
const zipRegex = helpers.regex(/^[0-9]{5}-[0-9]{3}$/);

const rules = {
  localClient: {
    nome: {
      required: defaultWithMessage('Nome', 'required', required),
      validName: defaultWithMessage('Nome', 'validName', nameValidator)
    },
    cgc_cpf: {
      required: defaultWithMessage('CPF/CNPJ', 'required', required)
    },
    celular: {
      required: defaultWithMessage('Celular', 'required', required),
      validCelular: defaultWithMessage('Celular', 'required', phoneRegex)
    },
    cep: {
      required: defaultWithMessage('CEP', 'required', required),
      validCep: defaultWithMessage('CEP', 'validCep', zipRegex)
    },
    rua: {required: defaultWithMessage('Rua', 'required', required)},
    numero: {required: defaultWithMessage('Numero', 'required', required)},
    bairro: {required: defaultWithMessage('Bairro', 'required', required)},
    cidade: {required: defaultWithMessage('Cidade', 'required', required)},
    uf: {required: defaultWithMessage('Estado', 'required', required)}
  }
};

const $v = useVuelidate(rules, {localClient});

const scrollToFirstInvalidField = () => {
  const firstInvalidField = Object.keys($v.value.localClient).find(field => {
    return $v.value.localClient[field].$invalid;
  });
  if (firstInvalidField && proxy.$refs[firstInvalidField]) {
    proxy.$refs[firstInvalidField].$el.scrollIntoView({behavior: 'smooth'});
    proxy.$refs[firstInvalidField].$el.querySelector('input').focus();
  }
};

const handleSubmit = async () => {
  $v.value.$touch()
  if ($v.value.$invalid) {
    scrollToFirstInvalidField()
    return
  }
  try {
    store.commit('setLoading', true);
    await store.dispatch('client/updateClientData', localClient.value);
    emit('save-client', localClient.value);
    proxy.$notify({
      type: 'success',
      duration: 3000,
      html: 'Seus dados foram atualizados!'
    });
  } catch (e) {
    console.error(e.error)
    proxy.$notify({
      type: 'danger',
      duration: 3000,
      html: e.message
    });
  } finally {
    store.commit('setLoading', false)

  }
};

const onBlurCep = async () => {
  try {
    store.commit('setLoading', true);

    const addressData = await fetchAddressByZip(localClient.value.cep);
    localClient.value.rua = addressData.logradouro;
    localClient.value.bairro = addressData.bairro;
    localClient.value.cidade = addressData.localidade;
    localClient.value.uf = addressData.uf;

  } catch (error) {
    console.error("Erro ao buscar CEP:", error.message);
    proxy.$notify({
      type: 'danger',
      duration: 3000,
      html: error.message
    });
  } finally {
    store.commit('setLoading', false)
  }
};

const handleImageUpload = ({ file, url }) => {
  localClient.value.binaryAvatar = file;
  localClient.value.avatar = url;
}

const handleImageDelete = () => {
  localClient.value.avatar = null;
  localClient.value.binaryAvatar = null;
}

const states = [
  {name: 'Acre', value: 'AC'},
  {name: 'Alagoas', value: 'AL'},
  {name: 'Amapá', value: 'AP'},
  {name: 'Amazonas', value: 'AM'},
  {name: 'Bahia', value: 'BA'},
  {name: 'Ceará', value: 'CE'},
  {name: 'Distrito Federal', value: 'DF'},
  {name: 'Espírito Santo', value: 'ES'},
  {name: 'Goiás', value: 'GO'},
  {name: 'Maranhão', value: 'MA'},
  {name: 'Mato Grosso', value: 'MT'},
  {name: 'Mato Grosso do Sul', value: 'MS'},
  {name: 'Minas Gerais', value: 'MG'},
  {name: 'Pará', value: 'PA'},
  {name: 'Paraíba', value: 'PB'},
  {name: 'Paraná', value: 'PR'},
  {name: 'Pernambuco', value: 'PE'},
  {name: 'Piauí', value: 'PI'},
  {name: 'Rio de Janeiro', value: 'RJ'},
  {name: 'Rio Grande do Norte', value: 'RN'},
  {name: 'Rio Grande do Sul', value: 'RS'},
  {name: 'Rondônia', value: 'RO'},
  {name: 'Roraima', value: 'RR'},
  {name: 'Santa Catarina', value: 'SC'},
  {name: 'São Paulo', value: 'SP'},
  {name: 'Sergipe', value: 'SE'},
  {name: 'Tocantins', value: 'TO'}
];

const sexos = [
  {name: 'Masculino', value: 'M'},
  {name: 'Feminino', value: 'F'},
  {name: 'Outros', value: ''}
];

const estados_civis = [
  {name: 'Solteiro', value: '1'},
  {name: 'Casado', value: '2'},
  {name: 'Viúvo', value: '3'},
  {name: 'Divorciado', value: '4'},
  {name: 'Outros', value: '5'},
]
</script>

<template>
  <form action="#" @submit.prevent="handleSubmit"
        class=" sm:border-gray-200 sm:border sm:p-4 sm:rounded-md w-full">

    <div class="w-full space-y-8 divide-gray-200 divide-y">

      <div class="space-y-4">
        <h2 class="text-lg sm:text-xl font-medium text-gray-900 dark:text-white inline-flex items-center">
          <svg class="w-6 h-6 text-gray-800 dark:text-white mr-2" aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
          </svg>
          Dados Pessoais
        </h2>

        <div class="flex flex-col sm:flex-row gap-4 sm:gap-8">

          <div class="sm:flex-shrink-0 flex items-center justify-center sm:justify-start">
            <avatar-client :avatar="localClient.avatar" @imageUploaded="handleImageUpload" @imageDeleted="handleImageDelete"/>
          </div>

          <div class="grid gap-3 grid-cols-6 w-full">
            <div class="col-span-6">
              <fwb-input ref="nome" label="Nome*" size="sm" required
                         v-model="localClient.nome"
                         :validation-status="$v.localClient.nome.$error ? 'error' : ''"
              />
              <span v-if="$v.localClient.nome.$error" class="text-xs text-red-600">
                  {{ $v.localClient.nome.$errors[0].$message }}
              </span>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <fwb-input ref="cgc_cpf" label="CPF/CNPJ*" size="sm"
                         v-mask="['###.###.###-###', '##.###.###/####-##']"
                         pattern="^(\d{3}\.\d{3}\.\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}/\d{4}-\d{2})$"
                         v-model="cgcCpf"
                         :validation-status="$v.localClient.cgc_cpf.$error ? 'error' : ''"
              />
              <span v-if="$v.localClient.cgc_cpf.$error" class="text-xs text-red-600">
                  {{ $v.localClient.cgc_cpf.$errors[0].$message }}
              </span>
            </div>

            <fwb-input class="col-span-3" label="Identidade" size="sm" v-model="localClient.documento"/>


            <fwb-input class="col-span-3 sm:col-span-2" label="Nascimento" size="sm" type="date"
                       v-model="dataNascimento"
            />

            <fwb-select class="col-span-3 sm:col-span-2 " label="Sexo" size="sm" :options="sexos"
                        v-model="localClient.sexo" placeholder="Selecione"
            />

            <fwb-select class="col-span-3 sm:col-span-2 " label="Estado Civil" size="sm" :options="estados_civis"
                        v-model="localClient.estado_civil" placeholder="Selecione"
            />
          </div>
        </div>

      </div>

      <div class="space-y-4">
        <h2 class="text-lg sm:text-xl font-medium text-gray-900 dark:text-white mt-4 inline-flex items-center">
          <svg class="w-6 h-6 text-gray-800 dark:text-white mr-2" aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"/>
          </svg>
          Contato
        </h2>
        <div class="grid grid-cols-2 gap-3 w-full">

          <fwb-input class="col-span-2 sm:col-span-1" label="E-mail" size="sm" type="email"
                     v-model="localClient.email"
          />

          <div class="col-span-1">
            <fwb-input ref="celular" label="Celular*" size="sm" required pattern="\(\d{2}\) \d{4,5}-\d{4}" type="tel"
                       v-mask="['(##) ####-####', '(##) #####-####']"
                       v-model="localClient.celular"
                       :validation-status="$v.localClient.celular.$error ? 'error' : ''"
            />
            <span v-if="$v.localClient.celular.$error" class="text-xs text-red-600">
                  {{ $v.localClient.celular.$errors[0].$message }}
              </span>
          </div>

          <fwb-input label="Telefone" size="sm" pattern="\(\d{2}\) \d{4,5}-\d{4}" type="tel"
                     v-mask="['(##) ####-####', '(##) #####-####']"
                     v-model="localClient.telefone"
          />

        </div>
      </div>

      <div class="space-y-4">
        <h2 class="text-lg sm:text-xl font-medium text-gray-900 dark:text-white mt-4 inline-flex items-center">
          <svg class="w-6 h-6 text-gray-800 dark:text-white mr-2" aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"/>
          </svg>
          Endereço
        </h2>
        <div class="grid gap-3 grid-cols-6 w-full">

          <div class="sm:col-span-2 col-span-6">
            <fwb-input ref="cep" label="CEP*" size="sm" required v-mask="'#####-###'"
                       v-model="localClient.cep" @blur="onBlurCep"
                       :validation-status="$v.localClient.cep.$error ? 'error' : ''"
            />
            <span v-if="$v.localClient.cep.$error" class="text-xs text-red-600">
             {{ $v.localClient.cep.$errors[0].$message }}
            </span>
          </div>

          <div class="sm:col-span-4 col-span-6">
            <fwb-input ref="rua" label="Rua*" size="sm" class="col-span-1 sm:col-span-2" required
                       v-model="localClient.rua"
                       :validation-status="$v.localClient.rua.$error ? 'error' : ''"
            />
            <span v-if="$v.localClient.rua.$error" class="text-xs text-red-600">
             {{ $v.localClient.rua.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-3 sm:col-span-2">
            <fwb-input ref="numero" label="Número*" size="sm" required
                       v-model="localClient.numero"
            />
            <span v-if="$v.localClient.rua.$error" class="text-xs text-red-600">
             {{ $v.localClient.rua.$errors[0].$message }}
            </span>
          </div>

          <fwb-input label="Complemento" size="sm" class="col-span-3 sm:col-span-2"
                     v-model="localClient.completa"
          />

          <div class="col-span-6 sm:col-span-2">
            <fwb-input ref="bairro" label="Bairro*" size="sm" required
                       v-model="localClient.bairro"
            />
            <span v-if="$v.localClient.bairro.$error" class="text-xs text-red-600">
             {{ $v.localClient.bairro.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 sm:col-span-2">
            <fwb-select ref="estado" label="Estado*" size="sm" required :options="states"
                        v-model="localClient.uf" placeholder="Selecione"
            />
            <span v-if="$v.localClient.uf.$error" class="text-xs text-red-600">
             {{ $v.localClient.uf.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 sm:col-span-2">
            <fwb-input ref="cidade" label="Cidade*" size="sm" required
                       v-model="localClient.cidade"
            />
            <span v-if="$v.localClient.cidade.$error" class="text-xs text-red-600">
             {{ $v.localClient.cidade.$errors[0].$message }}
            </span>
          </div>

        </div>

      </div>
      <div>
        <div class="flex justify-end gap-2 mt-4">
          <fwb-button class="w-full sm:w-40 rounded-md" type="submit" color="green">Salvar</fwb-button>
          <fwb-button v-if="cancelButton" class="w-full sm:w-40 rounded-md" type="button" color="dark" outline
                      @click="$emit('cancel')">
            Cancelar
          </fwb-button>
        </div>
      </div>
    </div>
  </form>

</template>

<style scoped>

</style>