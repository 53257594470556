<!-- CartFooter.vue -->
<template>
  <div class="p-4">
    <dl class="flex justify-between items-center">
      <dt class="text-gray-900 font-semibold">Total</dt>
      <dd class="text-gray-900 font-semibold">{{ totalPrice }}</dd>
    </dl>
    <button type="button" @click="handleCheckout"
            class="w-full mt-2 px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-md hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
      Pagamento
    </button>
  </div>
</template>

<script setup>
import router from "@/router";

defineProps({
  totalPrice: String,
});
const handleCheckout = (event) => {
  event.target.blur();
  router.push('/checkout/payment');
}
</script>
