import ClientService from "@/services/clientService";

export default {
    namespaced: true,
    state: {
        client: null,
    },
    mutations: {
        SET_CLIENT_DATA(state, data) {
            state.client = data;
        },
        UPDATE_CLIENT_DATA(state, data) {
            state.client = {...state.client , ...data};
        },
    },
    actions: {
        async updateClientData({commit}, payload){
            await ClientService.updateClient(payload)
            commit('UPDATE_CLIENT_DATA', payload);
        }
    },
    getters: {
        clientData: state => state.client,
    }
}