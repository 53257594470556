<template>
  <div class="flex items-center flex-col">
    <label class="mb-2 text-sm font-medium text-gray-900">Foto</label>
    <div  @click="triggerFileUpload"
        class="w-36 h-36 rounded-full border-4 border-gray-100 shadow-md bg-cover bg-center relative cursor-pointer"
        :style="{ backgroundImage: imageUrl ? `url(${imageUrl})` : 'none' }"
    >
      <div v-if="!imageUrl" class="w-full h-full rounded-full bg-gray-200"></div>

      <div class="absolute top-0 right-0">
        <label @click.stop
            class="w-8 h-8 bg-white rounded-full shadow-sm   flex items-center justify-center text-gray-500 border border-transparent cursor-pointer hover:bg-blue-500 hover:text-white hover:border-blue-500 transition-all">
          <input type="file" name="image-upload" class="hidden" @change="onImageUpload" ref="fileInput" accept="image/*"/>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 24 24">
            <path fill="currentColor"
                  d="M8.707 19.707L18 10.414L13.586 6l-9.293 9.293a1 1 0 0 0-.263.464L3 21l5.242-1.03c.176-.044.337-.135.465-.263M21 7.414a2 2 0 0 0 0-2.828L19.414 3a2 2 0 0 0-2.828 0L15 4.586L19.414 9z"/>
          </svg>
        </label>
      </div>

      <div v-if="imageUrl" class="absolute bottom-0 right-0">
        <button
            @click.prevent.stop="deleteImage"
            class="w-8 h-8 bg-white rounded-full shadow-sm flex items-center justify-center text-gray-500 border border-transparent cursor-pointer hover:bg-red-500 hover:text-white hover:border-red-500 transition-all"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref} from 'vue';

const {proxy} = getCurrentInstance();

const props = defineProps({
  avatar: Object
});
const emit = defineEmits(['imageUploaded', 'imageDeleted']);
const imageUrl = ref(props.avatar || null);
const fileInput = ref(null);

const onImageUpload = async (event) => {
  let file = event.target.files[0];
  if (file) {
    if (!file.type.startsWith('image/')) {
      proxy.$notify({
        type: 'danger',
        duration: 3000,
        html: 'Por favor, selecione um arquivo de imagem.'
      });
      return
    }

    if (file.size > 500 * 1024) {
      file = await compressImage(URL.createObjectURL(file));
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      imageUrl.value = e.target.result;
      const blob = new Blob([file], {type: file.type});
      emit('imageUploaded', {file, url: URL.createObjectURL(blob)});
    };
    reader.readAsDataURL(file);
  }
};
const triggerFileUpload = () => {
  fileInput.value.click();
};
const deleteImage = () => {
  imageUrl.value = null;
  emit('imageDeleted');
};

const compressImage = (urlImage, targetSizeKB = 500, maxIterations = 5) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = urlImage;
    img.onload = async () => {
      let iterations = 0;
      let compressedImageData = null;

      while (iterations < maxIterations) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let newWidth = img.width;
        let newHeight = img.height;

        if (newWidth > 1000 || newHeight > 1000) {
          const aspectRatio = newWidth / newHeight;

          if (aspectRatio > 1) {
            newWidth = 1000;
            newHeight = newWidth / aspectRatio;
          } else {
            newHeight = 1000;
            newWidth = newHeight * aspectRatio;
          }
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        await new Promise((resolve) => {
          canvas.toBlob((blob) => {
            compressedImageData = blob;
            resolve();
          }, 'image/jpeg', 0.7);
        });

        if (compressedImageData.size / 1024 <= targetSizeKB) {
          break;
        }

        img = new Image();
        img.src = compressedImageData;
        iterations++;
      }

      if (compressedImageData.size / 1024 > targetSizeKB) {
        reject(new Error(`Não foi possível atingir o tamanho desejado mesmo após ${maxIterations} iterações.`));
      } else {
        resolve(compressedImageData);
      }
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
}

</script>
