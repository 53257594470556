import axios from 'axios';
import store from '@/store';

export default {

    async fetchApi(param, data = undefined) {
        const API_URL = store.getters['catalog/hostCobolData'];
        const url = `${API_URL}${param}`;
        try {
            return await axios[data ? 'post' : 'get'](url, data);
        } catch (error) {
            console.error('Erro na requisição à API:', error);
            throw error;
        }
    },

    async readClient(cod) {
        try {
            const param = `runlib.cgi?prg=libcliente&param={cmd:read;key:${cod};out:json}`;
            const response = await this.fetchApi(param);
            return response.data; // Retorna os dados da API
        } catch (error) {
            console.error('Erro ao buscar cliente:', error);
            throw error;
        }
    },

    async createClient(data) {
        try {
            const param = `runlib.cgi?prg=libcliente&param={cmd:create;key:;out:json}`;
            const response = await this.fetchApi(param, data); // POST request
            return response.data; // Retorna os dados da criação
        } catch (error) {
            console.error('Erro ao criar cliente:', error);
            throw error;
        }
    },

    async updateClient(data) {
        try {
            const param = `runlib.cgi?prg=libcliente&param={cmd:update;key:;out:json}`;
            const response = await this.fetchApi(param, data);
            await this.saveAvatarCobol(data, 'update')
            return response.data;
        } catch (error) {
            console.error('Erro ao atualizar cliente:', error);
            throw error;
        }
    },

    async saveAvatarCobol(cliente, operacao) {
        const formData = new FormData();
        const sigla = cliente.nome.substring(0, 2).toUpperCase();
        let nameFile = cliente.avatar || "";
        if (cliente.binaryAvatar) {
            let file = cliente.binaryAvatar;
            if (!/\.\w+$/.test(file.name)) {
                const newFileName = file.name ?? 'image' + "." + file.type.split('/')[1];
                file = new File([file], newFileName, {type: file.type});
            }
            formData.append('myfile[]', file);
            formData.append('op', 2);
            formData.append('cd', cliente.codigo);
            formData.append('tp', '4');
            formData.append('sb', sigla);
            const response = await this.fetchApi(`web/upload.php`, formData);
            if (!response.data.length) {
                throw new Error('Erro ao salvar arquivo no servidor')
            }
            nameFile = response.data[0]
        }
        const data = {
            codigo: String(cliente.codigo).padStart(7, '0'),
            tipo: "cliente",
            avatar: nameFile
        };
        try {
            const response = await this.fetchApi(`runlib.cgi?prg=libcliente&param={cmd:${operacao}_catalogo;key:;out:json}`, data);

            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
};

