export default {
    install(app) {
        app.config.globalProperties.$formatNumber = (value, type) => {
            const formatters = {
                currency: { style: 'currency', currency: 'BRL' },
                float: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
                int: { maximumFractionDigits: 0 }
            };

            return new Intl.NumberFormat('pt-BR', formatters[type] || formatters.float).format(isNaN(value) ? 0 : value);
        };

        app.config.globalProperties.$getTextColorBasedOnBackground = (bgColor) => {
            const color = bgColor.replace('#', '');
            const [r, g, b] = [0, 2, 4].map(offset => parseInt(color.slice(offset, offset + 2), 16));
            return (r * 0.299 + g * 0.587 + b * 0.114) > 128 ? '#000000' : '#FFFFFF';
        };

    }
};
