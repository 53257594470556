<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    <CardProduto v-for="product in productsData" :key="product.id" :item="product" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CardProduto from '@/components/ProductCard/ProductCard.vue';

const store = useStore();
const productsData = computed(() => store.getters['catalog/filteredProducts']);
</script>
