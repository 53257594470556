<template>
  <div class="daisy-dropdown daisy-dropdown-end daisy-dropdown-hover" @mouseenter="hoverDropdown">
    <div tabindex="0" role="button" class="daisy-btn daisy-btn-ghost daisy-btn-circle" @click="goToPayment">
      <div class="daisy-indicator">
        <ShoppingCartIcon class="h-6 w-6 text-black-500"/>
        <span class="daisy-badge daisy-badge-sm daisy-indicator-item">{{ totalQuantity }}</span>
      </div>
    </div>
    <div
        tabindex="0"
        ref="cartDropdown"
        :class="{ 'fullscreen-dropdown': isFullscreen }"
        class="daisy-dropdown-content daisy-menu mt-1 shadow shadow-gray-300 bg-white rounded-md w-96 z-50 divide-y divide-gray-100"
    >
      <CartHeader :totalQuantity="totalQuantity"/>
      <div class="max-h-96 overflow-y-auto divide-y divide-gray-100">
        <CartItem v-for="product in cartSummary" :key="product.id" :product="product"
                  @productRemoved="handleProductRemoved"/>
      </div>
      <CartFooter :totalPrice="$formatNumber(totalPrice, 'currency')"/>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, nextTick, onMounted, onUnmounted} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router'
import {ShoppingCartIcon} from '@heroicons/vue/24/outline';

import CartHeader from './CartHeader.vue';
import CartItem from './CartItem.vue';
import CartFooter from './CartFooter.vue';

const store = useStore();
const router = useRouter();

const totalQuantity = computed(() => store.getters['cart/totalQuantity']);
const totalPrice = computed(() => store.getters['cart/totalPrice']);
const cartSummary = computed(() => store.getters['cart/cartSummary']);

const cartDropdown = ref(null);
const isFullscreen = ref(false);

const goToPayment = () => {
  router.push('/checkout/cart');
};

const hoverDropdown = async () => {
  isFullscreen.value = false;
  await nextTick(checkDropdownSize);
};

const checkDropdownSize = () => {
  const rect = cartDropdown.value?.getBoundingClientRect();
  isFullscreen.value = rect?.left < 0;
};

const handleProductRemoved = () => {
  if (cartDropdown.value && totalQuantity.value >= 1) {
    cartDropdown.value.focus(); // Define o foco no dropdown
  }
};
onMounted(() => window.addEventListener('resize', checkDropdownSize));
onUnmounted(() => window.removeEventListener('resize', checkDropdownSize));
</script>

<style scoped>
.fullscreen-dropdown {
  position: fixed !important;
  width: 100vw;
}
</style>
