<template>
  <div>
    <div
        v-if="isSidebarOpen"
        class="fixed inset-0 bg-gray-900 bg-opacity-50 z-30"
        @click="closeSidebar"
    ></div>

    <div
        :class="[
        'fixed top-0 left-0 w-full h-full max-w-sm bg-white bg-opacity-100 overflow-y-auto z-40 transform transition duration-150 ease-in-out p-4',
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      ]"
    >
      <slot name="content">
      </slot>
    </div>
  </div>
</template>

<script setup>

 defineProps({
  isSidebarOpen: Boolean
});

const emit = defineEmits(['update:isSidebarOpen']);

const closeSidebar = () => {
  emit('update:isSidebarOpen', false);
};
</script>

<style scoped>
</style>
