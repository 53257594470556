<template>
  <div
      @click="onCardClick"
      class="daisy-card bg-base-100 border rounded-md overflow-hidden cursor-pointer hover:shadow-2xl"
  >
    <product-card-image :images="item.imagens"/>
    <div class="p-2 sm:p-4 flex justify-between flex-col h-full">
      <p class="sm:text-sm text-xs font-semibold text-blue-700">{{ props.item.marca_desc }}</p>
      <h4 class="sm:text-md text-sm font-semibold leading-tight text-gray-900">{{ props.item.reference_name }} </h4>
      <p class="sm:text-sm text-xs font-medium text-gray-500 truncate">Cor: {{ props.item.color_name }}</p>
      <p class="sm:text-sm text-xs font-medium text-gray-500 truncate">Tamanho: {{ props.item.size }}</p>

      <span
          v-if="discount"
          class="bg-emerald-600 text-white text-xs font-bold px-2.5 py-0.5 rounded-full absolute top-4 left-4">
        {{ $formatNumber(props.item.discount, 'int') }}% off
      </span>

      <div class="flex items-center gap-4 mb-1">
        <span class="sm:text-xl text-lg font-bold">{{ $formatNumber(props.item.price, 'currency') }}</span>
        <p v-if="discount" class="text-xs font-medium text-gray-500 line-through">De
          {{ $formatNumber(props.item.original_price, 'currency') }}</p>
      </div>
      <AddToCartButton
          :product="props.item"
          :size="props.item.size"
      />
    </div>
  </div>
</template>

<script setup>
import {computed} from 'vue';
import {useRouter} from 'vue-router';
import AddToCartButton from "@/components/AddToCartButton.vue";
import ProductCardImage from "@/components/ProductCard/ProductCardImage.vue";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const router = useRouter();
const discount = computed(() => props.item.discount > 0);
// const priceOriginal = computed(() =>
//     props.item.discount
//         ? (props.item.price / (1 - props.item.discount / 100)).toFixed(2)
//         : null
// );

const onCardClick = () => {
  router.push({name: 'product-details', params: {id: props.item.id}});
};
</script>

<style scoped>
.card-d-row {
  @apply grid grid-cols-2 sm:flex sm:flex-col;
}

.image-d-row {
  @apply h-44 sm:h-56;
}
</style>
