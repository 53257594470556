<script setup>
import {computed, ref} from "vue";

const defaultImage = require('@/assets/images/not-image.gif');

const props = defineProps({
  images: {
    type: Array,
    required: true,
  },
});

const thumbnails = computed(() => props.images.map(image => ({
  src: image.file,
  alt: image.titulo,
})));

const selectedImage = ref(thumbnails.value[0]?.src || defaultImage);

</script>

<template>
  <div class="flex flex-col justify-center mb-4 lg:flex-row mx-auto">
    <ul class="grid gap-4 mt-8 order-2 lg:mt-0 lg:order-1 lg:flex-col lg:flex grid-cols-4">
      <li v-for="(image, index) in thumbnails" :key="index" class="me-2">
        <button
            class="w-20 h-20 p-2 border-2 rounded-md overflow-hidden cursor-pointer mx-auto sm:w-20 sm:h-20 md:w-24 md:h-24"
            :class="[ selectedImage === image.src ? 'text-blue-600 border-blue-600': ' text-gray-500 border-gray-100 ' ]"
            @mouseenter="selectedImage = image.src">
          <img class="object-contain w-full h-full" :src="image.src" :alt="image.alt">
        </button>
      </li>
    </ul>
    <div class="order-1 lg:order-2 w-full">
      <div class="px-4 bg-white rounded-md flex items-center justify-center">
        <img :src="selectedImage" alt="Selected Image" class="w-full mx-auto object-contain max-h-[600px]">
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>