import { h, render } from 'vue';
import AlertBase from "@/components/AlertBase.vue";

export default {
    install(app) {
        // Método global para chamar o alerta
        app.config.globalProperties.$alert = (options) => {
            const container = document.createElement('div');
            document.body.appendChild(container);

            const props = {
                type: options.type || 'dark',
                message: options.text || '',
                duration: options.duration,
                showCloseButton: options.showCloseButton !== false,
                showProgressBar: options.showProgressBar !== false,
            };

            const vnode = h(AlertBase, props, {
                title: options.title ? () => h('div', { innerHTML: options.title }) : null,
                htmlMessage: options.html ? () => h('div', { innerHTML: options.html }) : null,
            });

            vnode.props.onClose = () => {

                setTimeout(() => {
                    render(null, container);
                    document.body.removeChild(container)
                }, 500)
                ;
            };

            // Renderizar o VNode na div container
            render(vnode, container);
        };
    }
};