import axios from 'axios';

export const fetchAddressByZip = async (zip) => {
    try {
        const formattedZip = zip.replace(/\D/g, '');

        if (formattedZip.length !== 8) {
            throw new Error('CEP inválido.');
        }

        const response = await axios.get(`https://viacep.com.br/ws/${formattedZip}/json/`);

        if (response.data.erro) {
            throw new Error('CEP não encontrado.');
        }

        return response.data;
    } catch (error) {
        console.error(error.message);
        throw error;
    }
};
