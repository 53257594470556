import {createRouter, createWebHistory} from 'vue-router';
import authMiddleware from '@/middlewares/authMiddleware';
import store from '../store';
import Layout from '../layouts/MainLayout.vue';
import ProductSelection from '../views/ProductSelection.vue';
import ProductDetails from '../views/ProductDetails.vue';
import Checkout from '../views/CheckoutView.vue';
import NotFound from '../views/NotFound.vue';
import PerfilClient from "@/views/ProfileClient.vue";
import AccessDenied from "@/views/AccessDenied.vue";

const routes = [
    {
        path: '/',
        component: Layout,
        beforeEnter: authMiddleware,
        children: [
            {path: '', component: ProductSelection, name: 'home'},
            {path: 'produto/:id', component: ProductDetails, name: 'product-details'},
            {
                path: 'checkout', component: Checkout, name: 'checkout',
                beforeEnter: (to, from, next) => {
                    const cartItems = store.state.cart.cart;
                    next(!cartItems.length ? '/' : (to.path === '/checkout/' || to.path === '/checkout') ? '/checkout/cart' : undefined);
                },
                children: [
                    {
                        path: 'cart',
                        component: () => import('@/components/Checkout/CartStep.vue')
                    },
                    {
                        path: 'payment',
                        component: () => import('@/components/Checkout/PaymentStep.vue'),
                    },
                    {
                        path: 'confirmation',
                        component: () => import('@/components/Checkout/ConfirmationStep.vue'),
                    },
                ],
            },
            {path: 'profile', component: PerfilClient, name: 'profile-client'},
        ],
    },
    {path: '/404', component: NotFound},
    {path: '/401', component: AccessDenied},
    {path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound},

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const isConfirmationRoute = from.path === '/checkout/confirmation';

    if (isConfirmationRoute && to.path !== '/checkout/confirmation') {
        next(false);
    } else {
        next();
    }
});
// Verificação global antes de cada rota
// router.beforeEach((to, from, next) => {
//   // Verifica se o token está armazenado no Vuex
//
//   const token = store.state.token;
//   // Se a rota não for a principal e o token não estiver presente, exibe o componente 404
//   if (to.path !== '/' && !token) {
//     next({ name: 'not-found' }); // Redireciona para a página 404
//   } else {
//     next(); // Continua a navegação normalmente
//   }
// });

export default router;
