<template>
  <ol class="flex w-full max-w-2xl items-center text-center text-sm font-medium text-gray-500 sm:text-base">
    <li
        v-for="(step, index) in steps"
        :key="index"
        class="flex items-center"
        :class="{
        'text-blue-700': currentStep >= index + 1,
        'after:border-blue-700': currentStep > index + 1,
        'shrink-0':index === steps.length - 1,
        'after:mx-6 after:hidden after:h-1 after:w-full after:border-b after:border-gray-200 sm:after:inline-block xl:after:mx-10 md:w-full' : index < steps.length - 1
      }"
    >
      <span class="flex items-center after:mx-2 after:text-gray-200 after:content-['/'] sm:after:hidden"
            :class="{
          'cursor-pointer': index < currentStep,
          'pointer-events-none': index > currentStep
        }"
            @click="handleStepClick(index)"
      >

        <svg
            class="me-2 h-4 w-4 sm:h-5 sm:w-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
          <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        {{ step }}
      </span>
    </li>
  </ol>
</template>

<script setup>
import {computed} from 'vue';
import { useRoute,useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const currentStep = computed(() => {
  switch (route.path) {
    case '/checkout/cart':
      return 1;
    case '/checkout/payment':
      return 2;
    case '/checkout/confirmation':
      return 3;
    default:
      return 1;
  }
});

const steps = computed(() => ['Carrinho', 'Pagamento', 'Conclusão']);

const handleStepClick = (stepIndex) => {
  if (stepIndex === 0 && currentStep.value === 2) {
    router.push('/checkout/cart');
  }
};
</script>

<style scoped>
/* Caso queira adicionar alguma estilização personalizada */
</style>
