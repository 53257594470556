<template>
  <div class="container mx-auto sm:p-6 py-6 px-2">
    <div class="mb-2 items-center justify-between space-y-4 sm:flex sm:space-y-0 md:mb-3">
      <BreadCrumbs/>
    </div>
    <overview-body :product="product"/>
  </div>
</template>

<script setup>
import { computed} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
// import AddToCartButton from "@/components/AddToCartButton.vue";
import OverviewBody from "@/components/OverviewProduct/OverviewBody.vue";

const store = useStore();
const route = useRoute();
const productId = route.params.id;

const product = computed(() => store.getters['catalog/getProductById'](productId));

// const thumbnails = computed(() => product.value.imagens.map(image => ({
//   src: image.file,
//   alt: image.titulo,
// })));
// const discount = computed(() => product.value.discount > 0);
// const priceOriginal = computed(() =>
//     product.value.discount
//         ? (product.value.price / (1 - product.value.discount / 100)).toFixed(2)
//         : null
// );

// const selectedSize = ref(product.value.size);
//
// const stockSelected = computed(() => {
//   const estoqueItem = product.value.estoque.find(e => e.tamanho == selectedSize.value);
//   return estoqueItem ? estoqueItem.estoque : 0;
// });
//
// const selectSize = (size) => {
//   selectedSize.value = size;
//   console.log(stockSelected.value);
// };
//
// const selectedImage = ref(thumbnails.value[0]?.src || 'https://via.placeholder.com/400');
// const quantitySelected = ref(1)

</script>

<style>
/* Estilos personalizados */
</style>
