// services/stockService.js
import axios from 'axios';
import store from '@/store';

export default {
    async fetchStockCobol(branch, reference, color) {
        const cobol_url = store.getters['catalog/hostCobolData'];
        try {
            const response = await axios.get(`${cobol_url}runlib.cgi?prg=libestoque&param={cmd:read;key:${branch},${reference},${color};cp:grade_estoque;out:json}`);
            const {grade_estoque} = response.data.registro;
            return grade_estoque;
        } catch (error) {
            console.error('Erro ao buscar e atualizar o estoque do produto:', error);
            throw error;
        }
    },

    async getDataItem(id) {
        const API_URL = store.getters['api_url'];
        // const API_URL = 'http://sgasistemas.sgaweb.com.br/api/catalogs';

        try {
            const response = await axios.get(`${API_URL}/items/${id}`);
            return response.data.data
        } catch (error) {
            console.error('Erro ao buscar dados do catálogo:', error);
            throw error;
        }
    },

    async checkStock(productId, reference, color, size, quantity) {
        try {
            // const CATALOG = store.getters['catalog/catalogData'];

            // const stockData = await this.fetchStockCobol(CATALOG.branch, reference, color);
            const stockData = await this.getDataItem(productId);

            store.commit('catalog/UPDATE_PRODUCT', stockData);
            // store.commit('catalog/UPDATE_STOCK', { id_product: productId, estoque: stockData});
            // const stockInfo = stockData.find(e => e.tamanho === size);
            const stockInfo = stockData.estoque.find(e => e.tamanho === size);

            return stockInfo ? parseInt(stockInfo.estoque, 10) >= quantity : false;
        } catch (error) {
            console.error('Erro ao verificar se o produto pode ser adicionado ao carrinho:', error);
            throw error;
        }
    }
};
