<template>
  <fwb-button
      :size="sizeButton"
      class="w-full rounded-md focus:ring-2 inline-flex justify-center truncate"
      @click.stop.prevent="addToCart"
      :disabled="isDisabled">
    <template #prefix>
      <svg :class="[sizeButton === 'lg' ? 'h-6 w-6' : 'h-5 w-5']" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4h1.5L8 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm.75-3H7.5M11 7H6.312M17 4v6m-3-3h6"/>
      </svg>
    </template>
    {{ buttonText }}
  </fwb-button>

</template>

<script setup>
import {computed, getCurrentInstance} from 'vue';
import {useStore} from 'vuex';
import {FwbButton} from "flowbite-vue";

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    required: true,
  },
  sizeButton: {
    type: String,
    default: 'md',
  },
  quantity: {
    type: Number,
    default: 1,
  },
});
const {proxy} = getCurrentInstance();
const store = useStore();

const stockSelected = computed(() => {
  const estoqueItem = props.product.estoque.find(e => e.tamanho === props.size);
  return estoqueItem ? parseInt(estoqueItem.estoque) : 0;
});

const isDisabled = computed(() => stockSelected.value === 0);

const buttonText = computed(() => {
  return stockSelected.value > 0 ? 'Adicionar ao Carrinho' : 'Sem Estoque';
});

const addToCart = async () => {
  try {
    await store.dispatch('cart/addToCart', {
      id: `${props.product.id}-${Date.now()}`,
      id_product: props.product.id,
      reference: props.product.reference,
      color: props.product.color,
      size: props.size,
      quantity: props.quantity,
    });

  } catch (error) {
    proxy.$notify({
      type: 'danger',
      duration: 3000,
      html: error.message
    });
  }
};
</script>
