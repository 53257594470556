<template>
  <div
      :class="['daisy-navbar', 'shadow-md', config?.cor_2 ? '' : 'bg-base-100']"
      :style="{
        backgroundColor: config?.cor_2 || '',
        color: config?.cor_2 ? $getTextColorBasedOnBackground(config?.cor_2) : ''
      }">
    <div class="container mx-auto sm:px-6">
      <div class="daisy-navbar-start flex items-center">
        <router-link :to="{ name: 'home' }">
          <img :src="logoUrl" alt="Logo" class="h-12 w-auto"/>
        </router-link>
      </div>
      <div class="daisy-navbar-center hidden sm:block">
        <h4 class="text-xl font-semibold">{{ config.nome }}</h4>
      </div>
      <div class="daisy-navbar-end flex">
        <CartDropdown v-if="!isCheckout"/>
        <ProfileDropdown/>
      </div>
    </div>
  </div>
</template>

<script setup>

import CartDropdown from "@/components/CartNavbar/CartDropdown.vue";
import {useRoute} from 'vue-router';
import {computed} from "vue";
import {useStore} from "vuex";
import ProfileDropdown from "@/components/ProfileDropdown.vue";
import LogoMarca from '@/assets/images/logo-chapada.png'

const store = useStore();
const route = useRoute();
const sgawebUrl = store.getters['sgaweb_url'];
const isCheckout = computed(() => route.path.startsWith('/checkout'));
const config = computed(() => store.getters['config'] || {});
const logoUrl = computed(() => config?.value?.logo_marca ? `${sgawebUrl}/uploads/logo-empresa/${config.value.logo_marca}` : LogoMarca);

</script>

<style scoped>
</style>