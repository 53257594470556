<template>
  <footer
      :class="['p-4', 'mt-4', config?.cor_2 ? '' : 'bg-gray-700']"
          :style="{
        backgroundColor: config?.cor_2 || '',
        color: config?.cor_2 ? $getTextColorBasedOnBackground(config?.cor_2) : ''
      }">
    <div class="container mx-auto text-center">
      &copy; 2024 SGASoft. Todos os direitos reservados.
    </div>
  </footer>
</template>

<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
const store = useStore();

const config = computed(() => store.getters['config'] || {});

</script>