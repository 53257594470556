<template>
  <div v-if="isLoading"
    class="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-[1000] cursor-wait	">
    <img src="@/assets/images/Pictograma-Branco-Chapado.png" alt="Logo" class="w-24 animation-pulse" />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from 'vue';

export default {
  name: 'LoadingSpinner',
  setup() {
    const store = useStore();
    return {
      isLoading: computed(() => store.getters['isLoading']),
    };
  }

};
</script>
<style scoped>
.animation-pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    /* opacity: 1; */
  }

  50% {
    transform: scale(1.1);
    /* opacity: 0.7; */
  }

  100% {
    transform: scale(1);
    /* opacity: 1; */
  }
}
</style>
