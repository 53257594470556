<template>
  <details v-for="(category, index) in categories" :key="index" class="daisy-collapse daisy-collapse-arrow" open>
    <summary class="daisy-collapse-title text-md font-medium ">{{ category.title }}</summary>
    <div class="daisy-collapse-content">
      <div v-for="(item, key) in getCategoryItems(category.category)" :key="key" class="flex items-center mb-4">
        <input
            type="checkbox"
            :id="`checkbox-${category.category}-${key}`"
            :value="item.code"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 cursor-pointer"
            :checked="isChecked(category.category, item.code)"
            @change="handleCheckboxChange(category.category, item.code, $event.target.checked)"
        />
        <label :for="`checkbox-${category.category}-${key}`"
               class="ms-2 text-sm text-gray-900 cursor-pointer">{{ item.label }} ({{ item.count }})</label>
      </div>
    </div>
  </details>
</template>

<script setup>
import {computed} from 'vue';
import {useStore} from 'vuex';

const store = useStore();

const productsData = computed(() => store.getters['catalog/filteredProducts']);
const filters = computed(() => store.getters['catalog/filtersData']);

// Define as categorias
const categories = [
  {title: 'Marcas', category: 'marca', categoryDesc: 'marca_desc'},
  {title: 'Categoria', category: 'grupo', categoryDesc: 'grupo_desc'},
];
// Conta itens por categoria
const countByCategory = (items, category, categoryDesc) => {
  const aggregated = items.reduce((acc, item) => {
    const key = item[category];
    acc[key] = acc[key] || {code: item[category], label: item[categoryDesc], count: 0};
    acc[key].count++;
    return acc;
  }, {});
  return Object.values(aggregated).sort((a, b) => a.label.localeCompare(b.label));
};

// Obtém itens da categoria
const getCategoryItems = (category) => {
  const categoryData = categories.find(cat => cat.category === category);
  return categoryData ? countByCategory(productsData.value, categoryData.category, categoryData.categoryDesc) : {};
};

// Manipula a alteração do checkbox
const handleCheckboxChange = (category, code, checked) => {
  const currentFilters = {...filters.value};

  if (checked) {
    if (!currentFilters[category].includes(code)) {
      currentFilters[category].push(code);
    }
  } else {
    const index = currentFilters[category].indexOf(code);
    if (index > -1) currentFilters[category].splice(index, 1);
  }

  store.commit('catalog/SET_FILTERS_PRODUCTS', currentFilters);
};

// Verifica se o checkbox está selecionado
const isChecked = (category, code) => {
  return filters.value[category]?.includes(code) || false;
};

</script>

