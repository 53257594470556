<template>
  <div class="fixed top-0 right-0 p-4 z-50">
    <transition-group
        name="list" tag="div"
        class="space-y-2"
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-500"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        move-class="transition duration-500"
        move-delay="delay-300"
    >

      <Notification
          v-for="(notification) in notifications"
          :key="notification.id"
          :type="notification.type"
          :message="notification.message"
          :duration="notification.duration"
          :showProgressBar="notification.showProgressBar"
          :showCloseButton="notification.showCloseButton"
          @close="removeNotification(notification.id)"
      >
        <template #default v-if="notification.html">
          <div v-html="notification.html"></div>
        </template>
        <template #title v-if="notification.title">
          <div v-html="notification.title"></div>
        </template>
      </Notification>

    </transition-group>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import Notification from '@/components/notification/NotificationItem.vue';

const emit = defineEmits(['emptyList']);

const notifications = ref([]);

const addNotification = (notification) => {
  const id = Date.now();
  notifications.value.unshift({...notification, id});
};

const removeNotification = (id) => {
  notifications.value = notifications.value.filter(notification => notification.id !== id);
  if (notifications.value.length === 0) {
    emit('emptyList')
  }
};

defineExpose({addNotification});
</script>

<style scoped>

</style>

