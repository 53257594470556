<template>
  <nav class="hidden sm:flex" aria-label="Breadcrumb">
    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
      <!-- Início do breadcrumb -->
      <li class="inline-flex items-center">
        <router-link to="/"
                     class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
          <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
               viewBox="0 0 20 20">
            <path
                d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
          </svg>
          Inicio
        </router-link>
      </li>

      <!-- Breadcrumbs dinâmicos -->
      <li v-for="(breadcrumb, index) in breadcrumbs" :key="index">
        <div v-if="!breadcrumb.isActive" class="flex items-center">
          <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
               fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 9 4-4-4-4"/>
          </svg>
          <router-link :to="breadcrumb.path"
                       class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">
            {{ breadcrumb.name }}
          </router-link>
        </div>
        <div v-else class="flex items-center">
          <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
               fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 9 4-4-4-4"/>
          </svg>
          <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">{{ breadcrumb.name }}</span>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script setup>
import {computed} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from "vuex";

const store = useStore();
const route = useRoute();

const isProductDetails = computed(() => route.name === 'product-details');

const breadcrumbs = computed(() => {
  const pathArray = route.path.split('/').filter(p => p);

  if (isProductDetails.value) {
    const productId = route.params.id;

    const product = computed(() => {
      return store.getters['catalog/getProductById'](productId);
    })

    return [
      {
        name: 'Produto',
        path: route.path,
        isActive: true
      },
      {
        name: product.value.reference_name,
        path: "",
        isActive: true
      }
    ];
  }

  return pathArray.map((segment, index) => {
    let path = '/' + pathArray.slice(0, index + 1).join('/');

    return {
      name: segment.charAt(0).toUpperCase() + segment.slice(1),
      path,
      isActive: index === pathArray.length - 1
    };
  });
});
</script>

<style scoped>
</style>
