<script setup>
import AddToCartButton from "@/components/AddToCartButton.vue";
import {computed, ref} from "vue";

const props = defineProps({
  product: {
    type: Object,
    required: true
  }
})
const discount = computed(() => props.product.discount > 0);

// const priceOriginal = computed(() =>
//     props.product.discount
//         ? (props.product.price / (1 - props.product.discount / 100)).toFixed(2)
//         : null
// );

const selectedSize = ref(props.product.size);
const quantitySelected = ref(1)
const stockSelected = computed(() => {
  const estoqueItem = props.product.estoque.find(e => e.tamanho == selectedSize.value);
  return estoqueItem ? estoqueItem.estoque : 0;
});

const selectSize = (size) => {
  quantitySelected.value = 1
  selectedSize.value = size
}

</script>

<template>
  <div class="daisy-card p-5 sm:p-8 border rounded-md bg-gray-100">
    <h1 class="text-gray-900 font-semibold text-xl">{{ product.reference_name }}</h1>
    <h4 class="text-blue-700 font-semibold text-lg">{{ product.marca_desc }}</h4>
    <div class="flex items-start justify-between gap-4 mt-2 ">
      <div>
        <div v-if="discount" class="flex items-center gap-2">
          <p class="text-gray-900 font-bold text-lg leading-7 line-through">
            {{ $formatNumber(product.original_price, 'currency') }}</p>
          <div class="daisy-badge daisy-badge-success gap-2 font-medium text-white text-xs">
            {{ $formatNumber(product.discount, 'int') }}% off
          </div>
        </div>
        <p class="text-2xl leading-8 sm:text-3xl sm:leading-9 text-gray-900 font-extrabold">
          {{ $formatNumber(product.price, 'currency') }}
        </p>
      </div>
    </div>
    <p class="font-semibold text-base mt-3 text-gray-900">Cor: <span class="text-gray-700 ">{{
        product.color_name
      }}</span></p>
    <p class="text-gray-900 font-medium text-base mt-3">Tamanho:</p>
    <div class="grid gap-1 grid-cols-6 mt-1">
      <div
          v-for="(size, index) in product.estoque" :key="index"
          @click="size.estoque > 0 && selectSize(size.tamanho)"
          :class="[
                    'flex justify-center items-center flex-1 uppercase font-medium text-sm py-3 px-3 border border-gray-300 rounded-md',
                    size.estoque > 0 ? ' cursor-pointer' : 'border-dashed opacity-50 cursor-not-allowed',
                    selectedSize === size.tamanho ? 'bg-blue-700 text-white' : 'bg-white text-gray-900 '
                  ]"
      >
        {{ size.tamanho }}
      </div>
    </div>

    <div class="flex items-center sm:gap-4 flex-wrap mt-6">
      <div class="flex items-center space-x-4">
        <label for="quantity" class="text-gray-900 font-medium text-base">Quantidade:</label>
        <div class="relative">
          <select id="quantity" class="daisy-select daisy-select-ghost w-full max-w-xs"
                  v-model="quantitySelected">
            <option v-for="n in Math.min(parseInt(stockSelected), 10)" :key="n" :value="n">{{ n }} unidade(s)
            </option>
          </select>
        </div>
      </div>
      <span class="text-gray-500">({{ parseInt(stockSelected) }} disponíveis)</span>
    </div>

    <div class="mt-6">
      <AddToCartButton
          :product="product"
          :size="selectedSize"
          :quantity="quantitySelected"
          :sizeButton="'lg'"
      />
    </div>
  </div>
</template>

<style scoped>

</style>